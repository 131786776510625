




import { Component, Vue } from 'vue-property-decorator';
import AuthRegisterPassword from '@/app/modules/auth/components/AuthRegisterPassword.vue';

@Component({
  components: {
    AuthRegisterPassword,
  },
})
export default class AuthRegisterPasswordView extends Vue {
  /**
   * Getters
   */
  private get hash(): string {
    return this.$route.query.hash as string;
  }
}
